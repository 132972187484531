<template>
    <div class="cookies" v-if="!cookiesAccept">
        <div class="container text-center">
            <div>
                Ta strona wykorzystuje pliki cookies. Korzystanie ze strony bez zmiany ustawień przeglądarki
                internetowej dotyczących cookies oznacza zgodę na ich zapis i wykorzystanie. Więcej informacji można
                znaleźć w
                <a href="/polityka_prywatnosci_GPD_laciate.pdf" target="_blank">Polityce prywatności</a>.
                <a target="_blank" class="btn button button--small" @click="acceptCookies()">X</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CookiesBar",
    data() {
        return {
            cookiesAccept: false,
        };
    },
    methods: {
        acceptCookies() {
            this.$cookies.set("acceptCookies", true);
            this.cookiesAccept = true;
        },
    },
    mounted() {
        const cookies = this.$cookies.get("acceptCookies");
        if (cookies) {
            this.cookiesAccept = cookies;
        }
    },
};
</script>
