<template>
    <section-layout class="section-prize">
        <div class="-mt-4 -mt-sm-12">
            <img src="@/assets/prize.png" alt="" class="container ssection-prize__image d-none d-xs1-block"/>
            <img src="@/assets/prize_mobile.jpg" alt="" class="section-prize__image d-xs1-none"/>
        </div>
    </section-layout>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
    },
    components: {
    },
    computed: {
    },
};
</script>
