<template>
    <div class="form__signUp">
        <main-layout>
            <div class="-pt-nav">
                <div class="form container d-flex justify-content-center">

                    <img src="@/assets/signUp-pic1.png" alt="" class="form__signUp-phone-left" />
                    <img src="@/assets/signUp-pic2.png" alt="" class="form__signUp-phone-right" />

                    <div class="col-sm-20 form__signUp-phone-content">
                        <div class="-pt-6">
                            <p class="form__title form__row text-center heading-36"> Formularz zgłoszeniowy - Etap I </p>
                        </div>

                        <div class="form__row">
                            <p class="form__title heading-32 text-center">Rodzaj programu</p>
                            <p class="form__text text-20 text-center ">Proszę wybrać rodzaj programu, który Ciebie dotyczy?
                            </p>
                            <div
                                class="row -pl-0 -pr-0 d-flex flex-column align-items-center flex-sm-row justify-content-center form__gap-50">
                                <div class="form__col col-24 col-sm-12 -pl-0 -pr-0">
                                    <label for="" class="d-flex align-items-center form__gap-12">
                                        <input type="radio" v-model="programType" value="student" class="form-check-input">
                                        <div>
                                            <span class="heading-20">Program stypendialny<br></span>
                                            <span class="text-20">(dla uczniów)</span>
                                        </div>
                                    </label>
                                </div>
                                <div
                                    class="form__center-radio form__col col-24 col-sm-12 -pl-0 -pr-0 justify-content-center form__gap-50">
                                    <label for="" class="d-flex align-items-center form__gap-12">
                                        <input type="radio" v-model="programType" value="organization"
                                            class="form-check-input">
                                        <div>
                                            <span class="heading-20">Program grantowy<br></span>
                                            <span class="text-20">(dla organizacji)</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class=" d-flex justify-content-center -pt-5 -pb-1">
                                <router-link class="button__red form__btn" :to="{ name: 'signUpStudent' }">Przejdź dalej<img
                                        src="@/assets/right-btn.svg" alt="" class="button__arrow" /></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main-layout>
    </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";



export default {
    data() {
        return {
            programType: "student"
        }
    },
    components: {
        MainLayout,
    },
    computed: {
    }
};
</script>
