<template>
    <section-layout class="section-downloads d-flex justify-content-center">
        <div class="section-downloads__content col-sm-22">
            <div class="section-steps__downloads -pt-4 -pt-sm-8">
                <p class="heading-48 -pb-2 text-center">Pliki do pobrania:</p>

                <div>
                    <div class="section-downloads__items section-steps__link-items">
                        <div class="section-steps__link-item">
                            <p class="heading-18 color-blue">Regulamin programu „Enea Akademia Talentów” dla uczniów</p>
                            <a href="Regulamin_Konkursu_AT_20230904_final.pdf" target="_blank"
                                class="button__red">Pobierz<img src="@/assets/download-btn.svg" alt=""
                                    class="button__download" /></a>
                        </div>
                        <div class="section-steps__link-item">
                            <p class="heading-18 color-blue">Materiały informacyjne</p>
                            <a href="ENEA_AT_2023_ulotka_2xDL_09-2023_www.pdf" target="_blank"
                                class="button__red">Pobierz<img src="@/assets/download-btn.svg" alt=""
                                    class="button__download" /></a>
                        </div>
                        <div class="section-steps__link-item" v-if="stageStatus == 4">
                            <p class="heading-18 color-blue">Załączniki do zgłoszenia w II etapie</p>
                            <a href="Dokumenty_Etap_II.pdf" target="_blank"
                                class="button__red">Pobierz<img src="@/assets/download-btn.svg" alt=""
                                    class="button__download" /></a>
                        </div>
                        <div class="section-steps__link-item" v-if="stageStatus == 4">
                            <p class="heading-18 color-blue">Instrukcja dla uczniów jak przygotować się do drugiego etapu</p>
                            <a href="ENEA_AT_2023_manual.pdf" target="_blank"
                                class="button__red">Pobierz<img src="@/assets/download-btn.svg" alt=""
                                    class="button__download" /></a>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </section-layout>
</template>


<script>
// import pdf1 from "@/assets/pdf1.pdf";

export default {
    data() {
        return {
            // pdf1,
            showModal: false,
            changeContent: true,
        };
    },
    methods: {
    },
    components: {
    },
    computed: {
        stageStatus() {
            return this.$store.state.stage
        }
    },
};
</script>
