import axios from "axios";

import { createRouter, createWebHistory } from "vue-router";
import PageHome from "../pages/PageHome";
import PageAbout from "../pages/PageAbout";
import PageDownload from "../pages/PageDownload";
import PageNews from "../pages/PageNews";
import PageNewsItem from "../pages/PageNewsItem";
import PageContact from "../pages/PageContact";
import PageSignUp from "../pages/PageSignUp";
import PageApplications from "../pages/PageApplications";
import PageApplicationsItem from "../pages/PageApplicationsItem";
import PageVoteConfirmation from "../pages/PageVoteConfirmation";
import PageSignUpStudent from "../pages/PageSignUpStudent";
// import PageSignUpOrganization from "../pages/PageSignUpOrganization";
import PageThankYou from "../pages/PageThankYou";
import PageFormStep2 from "../pages/PageFormStep2";
import PageWinners from "../pages/PageWinners";

const routes = [
    {
        path: "/",
        name: "home",
        component: PageHome,
    },
    {
        path: "/o-programie",
        name: "about",
        component: PageAbout,
    },
    {
        path: "/aktualnosci",
        name: "news",
        component: PageNews,
    },
    {
        path: "/aktualnosci/:id",
        name: "newsItem",
        component: PageNewsItem,
    },
    {
        path: "/do-pobrania",
        name: "download",
        component: PageDownload,
    },
    {
        path: "/zglos-sie",
        name: "signUp",
        component: PageSignUp,
        async beforeEnter() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_BASE_URL + 'allowed'); // Zmień na rzeczywisty URL API
                if(response.data.stage !== 2) {
                    return { name: 'home' };
                }
              } catch (error) {
                return { name: 'home' };
              }
        },

    },
    {
        path: "/zglos-sie/student",
        name: "signUpStudent",
        component: PageSignUpStudent,
        async beforeEnter() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_BASE_URL + 'allowed'); // Zmień na rzeczywisty URL API
                if(response.data.stage !== 2) {
                    return { name: 'home' };
                }
              } catch (error) {
                return { name: 'home' };
              }
        },
    },
    // {
    //     path: "/zglos-sie/organizacja",
    //     name: "signUpOrganization",
    //     component: PageSignUpOrganization,
    // },
    {
        path: "/kontakt",
        name: "contact",
        component: PageContact,
    },
    {
        path: "/dziekujemy",
        name: "thankYou",
        component: PageThankYou,
    },
    {
        path: "/formularz-finalista/:type/:uuid",
        name: "formStep2",
        component: PageFormStep2,
        async beforeEnter() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_BASE_URL + 'allowed'); // Zmień na rzeczywisty URL API
                if(response.data.stage !== 4) {
                    return { name: 'home' };
                }
              } catch (error) {
                return { name: 'home' };
              }
        },
    },
    {
        path: "/glosowanie",
        name: "applications",
        component: PageApplications,
        async beforeEnter() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_BASE_URL + 'allowed'); // Zmień na rzeczywisty URL API
                if(response.data.stage !== 7 && response.data.stage !== 6 && response.data.stage !== 8 ) {
                    return { name: 'home' };
                }
              } catch (error) {
                return { name: 'home' };
              }
        },
    },
    {
        path: "/glosowanie/:id",
        name: "applicationsItem",
        component: PageApplicationsItem,
        async beforeEnter() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_BASE_URL + 'allowed'); // Zmień na rzeczywisty URL API
                if(response.data.stage !== 7 && response.data.stage !== 6 && response.data.stage !== 8 && response.data.stage !== 9) {
                    return { name: 'home' };
                }
              } catch (error) {
                return { name: 'home' };
              }
        },
    },
    {
        path: "/glos/:id",
        name: "vote",
        component: PageVoteConfirmation,
        async beforeEnter() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_BASE_URL + 'allowed'); // Zmień na rzeczywisty URL API
                if(response.data.stage !== 7) {
                    return { name: 'home' };
                }
              } catch (error) {
                return { name: 'home' };
              }
        },
    },
    {
        path: "/zwyciezcy",
        name: "winners",
        component: PageWinners,
        async beforeEnter() {
            try {
                const response = await axios.get(process.env.VUE_APP_API_BASE_URL + 'allowed'); // Zmień na rzeczywisty URL API
                if(response.data.stage !== 9) {
                    return { name: 'home' };
                }
              } catch (error) {
                return { name: 'home' };
              }
        },
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: function (to) {
        if (to.hash) {
            if (window.innerWidth < 1025) {
                return {
                    el: to.hash,
                    top: 100,
                };
            } else {
                return {
                    el: to.hash,
                    top: 255,
                };
            }
        } else {
            return { top: 0 };
        }
    },
});

export default router;
