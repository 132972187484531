<template>
    <nav class="footer__menu" aria-label="secondary-navigation">
        <div class="footer__menu-item">
            <a
                href="https://www.enea.pl/"
                target="_blank"
                class="footer__menu-link"
                >Enea.pl</a
            >
        </div>
        <div class="footer__menu-item">
            <a
                href="/regulamin-serwisu_2023_final.pdf"
                target="_blank"
                class="footer__menu-link"
                >Regulamin serwisu</a
            >
        </div>
        <div class="footer__menu-item">
            <a href="/polityka-prywatnosci.pdf" target="_blank" class="footer__menu-link"
                >Polityka prywatności</a
            >
        </div>
        <div class="footer__menu-item">
            <router-link :to="{ name: 'contact' }" class="footer__menu-link">Kontakt</router-link>
        </div>
    </nav>
</template>
