<template>
    <section-layout class="section-header">
        <div class="section-header__content text-center d-flex align-items-center">
            <div class="container d-flex flex-column align-items-center ">
                <img src="@/assets/logo.svg" alt="" class="section-header__logo " />
                <p class="section-header__text text-24 col-sm-14">
                    <span class="heading-24">Enea Akademia Talentów</span> to wspólna inicjatywa Enei i Fundacji Enea, której celem jest <span class="heading-24">wsparcie młodych talentów poprzez atrakcyjne stypendia.<br></span> W tej edycji pula nagród wynosi<span class="heading-24"> 200 000 zł.</span>
                    <br>Do konkursu zapraszamy wszystkie utalentowane dzieci i młodzież ze szkół podstawowych i ponadpodstawowych. 
                </p>
            </div>
        </div>
    </section-layout>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
    },
    components: {
    },
    computed: {
    },
};
</script>
