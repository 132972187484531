<template>
    <SiteHeader />
    <main class="main">
        <slot></slot>
    </main>
    <SiteFooter />
    <CookiesBar />
</template>

<script>
import SiteHeader from "@/components/SiteHeader"
import SiteFooter from "@/components/SiteFooter"
import CookiesBar from "@/components/parts/CookiesBar"

export default {

    components: {
        SiteHeader,
        SiteFooter,
        CookiesBar
    }
}
</script>