<template>
    <div class="form-data">
        <p class="heading-24 form__title -pt-4 form__pb-8">Zdjęcie na profilu {{$route.params.type == 'organizacja' ? 'Organizacji' : 'Uczestnika'}} <span
                class="form__label form__label--red">*</span></p>
        <p class="text-18 form__title form__pb-8">
            Zdjęcie zostanie wyświetlone na stronie Enei Akademii Talentów w ramach profilu Uczestnika.
        </p>
        <p class="form__pt-24 form__main-description">Zdjęcie powinno spełniać następujące wymagania:</p>
        <ul class="form__main-description">
            <li>­rozmiar zdjęcia nie może przekraczać 4 MB;</li>
            <li>­minimalny rozmiar 200 px x 200 px lub 5 cm x 5 cm;</li>
            <li>­Zdjęcie powinno mieć kształt kwadratu;</li>
            <li>­minimalna rozdzielczość 72 dpi;</li>
            <li>na zdjęciu może znajdować się jedynie twarz ucznia bez osób trzecich</li>
        </ul>
        <div class="form__pt-24 d-flex align-items-center">
            <div class="button__red">
                <input type="file" id="file-img" class="form-control d-none"
                    v-on:change="(e) => onChange(e, 'image')" />
                <label for="file-img"> Dodaj zdjęcie <img src="@/assets/add-btn.svg" alt=""
                        class="button__add" /></label>
            </div>
            <div class="-ml-3">
                {{ image.name }}
            </div>
        </div>
        <div class="text-danger" v-if="errorImage">
            {{ errorImage }}
        </div>

        <p class="heading-24 form__title -pt-4">Film na platformie YouTube <span
                class="form__label form__label--red">*</span></p>
        <p class="text-18 form__title form__pt-16">
            Wklej link do filmu opublikowanego na kanale YouTube
        </p>
        <p class="form__pt-16 form__main-description">
            Film na kanale YouTube musi mieć ustawiony status publiczny. Szczegóły wykonania zadania konkursowego i jego
            umieszczenia zawarte są w Regulaminie Akcji Enea Akademia Talentów oraz w części Do pobrania
        </p>
        <p class="text-18 form__title form__pt-16 form__pb-8">
            Link do filmu <span class="form__label form__label--red">*</span>
        </p>
        <div class="col-md-12">
            <input type="text" class="form-control form__textarea" v-model="youTube" />
        </div>
        <div class="text-danger" v-if="errorYouTube">
            {{ errorYouTube }}
        </div>

        <div class="d-flex flex-column form__gap-24" v-if="$route.params.type == 'organizacja'">
            <p class="heading-24 form__title -pt-4">Załączniki dodatkowe (opcjonalne) </p>
            <p class="form__main-description">Skan pisemnego oświadczenia Osoby Reprezentującej Organizację, której dane
                zostały podane w Formularzu do II etapu Akcji „Enea Akademia Talentów” (wzór stanowi Załącznik nr 2 do
                regulaminu) </p>
            <div>

                <div class="d-flex align-items-center">
                    <div class="button__red">
                        <input type="file" class="form-control d-none" id="statement-file"
                            v-on:change="(e) => onChange(e, 'statement')" />
                        <label for="statement-file"> Dodaj skan dokumentu <img src="@/assets/add-btn.svg" alt=""
                                class="button__add" /></label>
                    </div>
                    <div class="-ml-3">
                        {{ statement.name }}
                    </div>
                </div>
                <div class="text-danger" v-if="errorStatement">
                    {{ errorStatement }}
                </div>
            </div>
            <p class="form__main-description">Skan dokumentu potwierdzającego umocowanie wskazanej osoby do
                reprezentowania Organizacji np. pełnomocnictwo, wyciąg z KRS itp. (tylko w przypadku, jeśli nastąpiła
                zmiana Osoby Reprezentującej)</p>

            <div>
                <div class="d-flex align-items-center">
                    <div class="button__red">
                        <input type="file" class="form-control d-none" id="power-attorne-file"
                            v-on:change="(e) => onChange(e, 'power_attorney')" />
                        <label for="power-attorne-file"> Dodaj skan pełnomocnictwa <img src="@/assets/add-btn.svg"
                                alt="" class="button__add" /></label>
                    </div>
                    <div class="-ml-3">
                        {{ power_attorney.name }}
                    </div>
                </div>
                <div class="text-danger" v-if="errorPowerAttorney">
                    {{ errorPowerAttorney }}
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    components: {
    },
    computed: {
        errorImage() {
            const error = this.$store.getters.getError('image');
            if (error && error.length)
                return error[0]

            return null
        },
        errorPowerAttorney() {
            const error = this.$store.getters.getError('power_attorney');
            if (error && error.length)
                return error[0]

            return null
        },
        errorStatement() {
            const error = this.$store.getters.getError('statement');
            if (error && error.length)
                return error[0]

            return null
        },
        errorYouTube() {
            const error = this.$store.getters.getError('application.youtube_link');
            if (error && error.length)
                return error[0]

            return null
        }
    },
    watch: {
        youTube(newVal) {
            const form = this.$route.params.type == "organizacja" ? 'formOrganizationStep2.application.youtube_link' : 'formStudentStep2.application.youtube_link';
            // console.log(form)
            this.updateFormValue(form, newVal)
        }
    },
    methods: {
        onChange(e, name) {
            // console.log(name)
            if (!e.target.files.length) {
                this[name].name = null
                this.updateFormValue('files.' + name, null)
                return
            }
            this[name].name = e.target.files[0].name
            this.updateFormValue('files.' + name, e.target.files[0])
        },
    },
    data() {
        return {
            youTube: null,
            image: {
                size: '',
                height: '',
                width: '',
                name: ''
            },
            statement: {},
            power_attorney: {},
            imageLoaded: false
        }
    },
};
</script>
