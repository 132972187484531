<template>
    <div class="form-data">
        <div>
            <div class="form__title heading-24 text-center -pt-4 form__pb-8">
                Dane ucznia
            </div>
            <p class="text-20 text-center">Dane osoby ubiegającej się o stypendium.</p>

            <!-- Dane ucznia -->
            <div class="row form__pt-24 form__gap-24">
                <div class="col-md-12">
                    <text-input :data="inputs.name" />
                </div>
                <div class="col-md-12">
                    <text-input :data="inputs.surname" />
                </div>
                <div class="col-md-12">
                    <!-- <text-input :data="inputs.student_birth_date" /> -->
                    <date-input type="date" :data="inputs.student_birth_date" />

                </div>
                <div class="col-md-12">
                    <text-input :data="inputs.student_class" />
                </div>
                <div class="col-md-12" v-if="!isParent">
                    <text-input-number :data="inputs.phone" />
                </div>
                <div class="col-md-12" v-if="!isParent">
                    <text-input :data="inputs.email" />
                </div>
            </div>

            <div class="form__title heading-24 text-center -pt-4 form__pb-8">
                Kategoria talentu: {{ category }}
            </div>
            <div class="col-md-16 offset-md-4 mt-4 text-danger text-center">
                UWAGA. Jeśli dane w tej części uległy zmianie w porównaniu z danymi, które są podane poniżej, należy je
                poprawić (dane, które się wyświetlają, zostały podane w I etapie).
            </div>

            <p class="form__title text-center heading-24 -pt-4">Adres zamieszkania ucznia</p>
            <address-form stage-name="formStudentStep2.student.address" class="form__pt-24 form__gap-24" />

            <!-- Dane gdy uczeń pełnoletni -->
            <div v-if="!isParent">
                <radio-input v-model.number="form.student.contact_address_select"
                    :data="inputs.contact_address_select" />
                <address-form stage-name="formStudentStep2.student.contact_address"
                    v-if="form.student.contact_address_select == 'yes'" v-model="form.student.contact_address" />
            </div>

            <!-- Dane gdy uczeń niepełnoletni -->
            <div v-if="isParent">
                <div class="heading-24 form__title -pt-4 text-center">
                    Dane wnioskodawcy
                </div>
                <div class="row form__pt-24 form__gap-24">
                    <div class="col-md-12">
                        <text-input v-model="form.parent.name" :class="{ 'form-control__error': error }"
                            :data="inputs.parent_name" />
                    </div>
                    <div class="col-md-12">
                        <text-input v-model="form.parent.surname" :data="inputs.parent_surname" />
                    </div>
                    <div class="col-md-12">
                        <text-input-number v-model="form.parent.phone" :data="inputs.parent_phone" />
                    </div>
                    <div class="col-md-12">
                        <text-input v-model="form.parent.email" :data="inputs.parent_email" />
                    </div>
                </div>

                <radio-input v-model.number="form.parent.address_select" :data="inputs.address_select_parent" />
                <address-form stage-name="formStudentStep2.person.address" v-if="form.parent.address_select == 'yes'"
                    v-model="form.parent.address" />

                <radio-input v-model.number="form.parent.contact_address_select"
                    :data="inputs.contact_address_select_parent" />
                <address-form stage-name="formStudentStep2.person.contact_address"
                    v-if="form.parent.contact_address_select == 'yes'" v-model="form.parent.address" />
            </div>
        </div>
    </div>
</template>

<script>
import RadioInput from "@/components/inputs/RadioInput.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import TextInputNumber from "@/components/inputs/TextInputNumber.vue";
// import OrganizationData from "@/components/forms/OrganizationData.vue";
import AddressForm from "@/components/forms/AddressFormAutoload.vue";
import DateInput from "@/components/inputs/DateInput.vue";



export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],

    components: {
        RadioInput,
        TextInput,
        TextInputNumber,
        AddressForm,
        DateInput
    },
    mounted() {
        this.isParent = this.$store.getters.getValuByPath('formStudentStep2.student.email') ? false : true;
    },
    computed: {
        person_reporting() {
            return this.$store.getters.getValuByPath('formStudent.student.person_reporting');
        },
        category() {
            const arr = {
                'art': 'sztuka',
                'science': 'nauka',
                'sport': 'sport',
                'social': 'zaangażowanie społeczne'
            };
            const cat = this.$store.getters.getValuByPath('formStudentStep2.student.category');
            return arr[cat]
        },
    },
    watch: {
        reporting_person(newVal) {
            if (newVal == 'student') {
                this.updateFormValue('formStudent.application.type', 'secondary_school');
            } else {
                this.updateFormValue('formStudent.application.type', null)
            }
        }
    },
    methods: {
    },
    data() {
        return {
            isParent: false,
            reporting_person: null,
            inputs: {
                name: {
                    type: 'text',
                    title: 'Imię/Imiona',
                    placeholder: 'Imię/Imiona',
                    mandatory: '*',
                    subtitle: null,
                    model: 'student_name',
                    autoload: true,
                    stage: 'formStudentStep2.student.name'
                },
                surname: {
                    type: 'text',
                    title: 'Nazwisko',
                    placeholder: 'Nazwisko',
                    subtitle: null,
                    mandatory: '*',
                    model: 'student_surname',
                    autoload: true,
                    stage: 'formStudentStep2.student.surname'
                },
                student_birth_date: {
                    type: 'text',
                    title: 'Data urodzenia fff',
                    placeholder: 'Data urodzenia',
                    subtitle: null,
                    mandatory: '*',
                    model: 'student_birth_date',
                    autoload: true,
                    stage: 'formStudentStep2.student.birth_date'
                },
                student_class: {
                    type: 'text',
                    title: 'Klasa',
                    placeholder: 'Klasa',
                    subtitle: null,
                    mandatory: '*',
                    autoload: true,
                    model: 'student_class',
                    stage: 'formStudentStep2.student.class'
                },
                phone: {
                    type: 'text',
                    title: 'Nr telefonu',
                    placeholder: 'Nr telefonu',
                    subtitle: null,
                    mandatory: '*',
                    autoload: true,
                    tooltip: 'Z tym numerem telefonu zostanie podjęta próba połączenia w razie potrzeby.',
                    model: 'student_phone',
                    stage: 'formStudentStep2.student.phone'
                },
                email: {
                    type: 'text',
                    title: 'E-mail',
                    placeholder: 'E-mail',
                    subtitle: null,
                    autoload: true,
                    mandatory: '*',
                    tooltip: 'Na ten adres zostanie wysłany wypełniony formularz.',
                    model: 'student_email',
                    stage: 'formStudentStep2.student.email'
                },
                contact_address_select: {
                    type: 'radio',
                    title: 'Adres korespondencyjny',
                    subtitle: 'Należy wybrać jedną z dwóch opcji:',
                    // flexRow: true,
                    autoload: true,
                    value: 'formStudentStep2.student.contact_address.city',
                    options: [
                        {
                            name: 'taki jak adres zamieszkania ucznia',
                            value: 'no'
                        },
                        {
                            name: 'inny',
                            value: 'yes'
                        }
                    ],
                    model: 'contact_address_select',
                    stage: 'formStudentStep2.student.contact_address_select'
                },
                parent_name: {
                    type: 'text',
                    title: 'Imię/Imiona',
                    placeholder: 'Imię/Imiona',
                    subtitle: null,
                    mandatory: '*',
                    autoload: true,
                    model: 'parent_name',
                    stage: 'formStudentStep2.person.name'
                },
                parent_surname: {
                    type: 'text',
                    title: 'Nazwisko',
                    placeholder: 'Nazwisko',
                    subtitle: null,
                    mandatory: '*',
                    autoload: true,
                    model: 'parent_surname',
                    stage: 'formStudentStep2.person.surname'
                },
                parent_phone: {
                    type: 'text',
                    title: 'Nr telefonu',
                    placeholder: 'Nr telefonu',
                    subtitle: null,
                    mandatory: '*',
                    autoload: true,
                    tooltip: 'Z tym numerem telefonu zostanie podjęta próba połączenia w razie potrzeby.',
                    model: ' parent_phone',
                    stage: 'formStudentStep2.person.phone'
                },
                parent_email: {
                    type: 'text',
                    title: 'E-mail',
                    placeholder: 'E-mail',
                    subtitle: null,
                    mandatory: '*',
                    autoload: true,
                    tooltip: 'Na ten adres zostanie wysłany wypełniony formularz.',
                    model: 'parent_email',
                    stage: 'formStudentStep2.person.email'
                },
                address_select_parent: {
                    type: 'radio',
                    title: 'Adres zamieszkania Wnioskodawcy',
                    subtitle: null,
                    mandatory: '*',
                    // flexRow: true,
                    options: [
                        {
                            name: 'taki jak adres zamieszkania ucznia',
                            value: 'no'
                        },
                        {
                            name: 'inny',
                            value: 'yes'
                        }
                    ],
                    autoload: true,
                    value: 'formStudentStep2.person.contact_address.city',
                    model: 'address_select_parent',
                    stage: 'formStudentStep2.person.address_select'
                },
                contact_address_select_parent: {
                    type: 'radio',
                    title: 'Adres korespondencyjny',
                    mandatory: '*',
                    subtitle: null,
                    // flexRow: true,
                    options: [
                        {
                            name: 'taki jak adres zamieszkania ucznia',
                            value: 'no'
                        },
                        {
                            name: 'inny',
                            value: 'yes'
                        }
                    ],
                    autoload: true,
                    value: 'formStudentStep2.person.contact_address.city',
                    model: 'contact_address_select_parent',
                    stage: 'formStudentStep2.person.contact_address_select'
                },

            },
            form: {
                type: null,
                student: {
                    name: '',
                    surname: '',
                    birth_date: '',
                    class: '',
                    phone: '',
                    email: '',
                    desc: '',
                    address: {},
                    contact_address_select: null,
                    contact_address: {}
                },
                parent: {
                    name: '',
                    surname: '',
                    phone: '',
                    email: '',
                    address_select: null,
                    address: {},
                    contact_address_select: null,
                    contact_address: {}
                }
            }
        }
    },
};
</script>
