<template>
    <section-layout class="container section-participants">
        <div class="section-participants__container d-flex justify-content-center">
            <div class="col-sm-20 -mt-4 -mt-sm-12">
                <div class="section-participants__content">
                    <p class="section-participants__title section-participants__title--blue heading-48 text-center -pb-4">
                        Kto może się zgłosić?
                    </p>
                    <div class="row">
                        <div class="col-md-12 offset-md-6">
                            <div class="section-participants__items">
                                <div class="section-participants__item d-flex flex-column align-items-center">
                                    <div class="d-flex justify-content-center">
                                        <img src="@/assets/students.svg" alt="" class="section-participants__image" />
                                    </div>
                                    <p
                                        class="section-participants__subtitle section-participants__subtitle--blue heading-48 text-center">
                                        Uczniowie</p>
                                    <p class="section-participants__text text-16"><span class="heading-16">O STYPENDIUM 5000
                                            zł</span> mogą walczyć UCZNIOWIE:</p>
                                    <ul class="d-flex flex-column list">
                                        <li>ze szkół podstawowych (od IV klasy)</li>
                                        <li>ze szkół ponadpodstawowych</li>
                                    </ul>
                                    <div class="d-flex justify-content-center mt-2">
                                        <router-link v-if="stageStatus == 2" :to="{ name: 'signUpStudent' }"
                                            class="button__red">Zgłoś się<img src="@/assets/right-btn.svg" alt=""
                                                class="button__arrow" /></router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-participants__video -mt-4 -mt-sm-12 video-container">
                    <div id="poster-image" class="section-participants__video" @click="fadeImage"></div>
                    <iframe id="videoContainer" src="https://www.youtube.com/embed/8ELhQHlxRfY" title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>

            </div>
        </div>
    </section-layout>
</template>

<script>

export default {
    data() {
        return {
            showModal: false,
        };

    },
    methods: {
        fadeImage() {
            document.getElementById("poster-image").style.display = "none";
            document.getElementById("videoContainer").src = "https://www.youtube.com/embed/8ELhQHlxRfY?autoplay=1";
        }

    },
    components: {
    },
    computed: {
        applicationActive() {
            return this.$store.state.applicationActive
        },
        stageStatus() {
            return this.$store.state.stage
        }
    },
};
</script>

<style>
.video-container {
    position: relative;
    padding-bottom: 56.25%;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px;
}
</style>
