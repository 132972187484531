<template>
            <div class="modal fade show d-flex justify-content-center" id="staticBackdrop" style="display: block">
                <div class="modal__dialog">
                    <div class="container">
                        <div class="modal__content col-sm-20">
                            <div class="modal-header">
                                <div v-if="type == 'success'" class="modal-body">
                                    <p class="modal-title heading-32 text-center form__title" id="staticBackdropLabel">Dziękujemy za przesłanie formularza</p>
                                </div>
                                <div v-if="type == 'success-finalist'" class="modal-body">
                                    <p class="modal-title heading-32 text-center form__title" id="staticBackdropLabel">Dziękujemy za przesłanie formularza</p>
                                </div>
                                <div v-if="type == 'finalist-accept'" class="modal-body">
                                    <p class="modal-title heading-32 text-center form__title" id="staticBackdropLabel">Już wysłałeś swoje zgłoszenie</p>
                                </div>
                                <div v-if="type == 'contact-form'" class="modal-body">
                                    <p class="modal-title heading-32 text-center form__title" id="staticBackdropLabel">Twój formularz został wysłany</p>
                                </div>
                                <div v-if="type == 'server-error'" class="modal-body">
                                    <p class="modal-title heading-32 text-center form__title" id="staticBackdropLabel">Błąd</p>
                                </div>
                                <!-- <router-link :to="{ name: 'home' }" class="btn-close modal__btn-close"></router-link> -->
                                <span  @click="$emit('closeModal')" class="btn-close modal__btn-close"></span>
                            </div>
                            <div v-if="type == 'success'" class="modal-body d-flex justify-content-center">
                                <div class="col-16 text-center">
                                    <p class="modal__heading form__pb-8">Wyniki już 10 listopada 2023 roku!</p>
                                    <p class="modal__text text-20">W tym dniu wyślemy do Ciebie maila z informacją, czy dotarłeś do drugiego etapu konkursu, w którym będziesz musiał przygotować zadanie konkursowe - film, w którym opowiesz o swojej pasji.</p>
                                    <p class="modal__heading">Trzymamy kciuki!</p>
                                </div>
                            </div>
                            <div v-if="type == 'success-finalist'" class="modal-body d-flex justify-content-center">
                                <div class="col-16 text-center">
                                    <p class="modal__text text-20">Twoje dane zostały poprawnie zapisane.</p>
                                </div>
                            </div>
                            <div v-if="type == 'finalist-accept'" class="modal-body d-flex justify-content-center">
                                <div class="col-16 text-center">
                                    <p class="modal__text text-20">Twoje dane zostały już zapisane. Jeżeli to nie ty wypełniłeś formularz, skontaktuj się z organizatorem</p>
                                </div>
                            </div>
                            <div v-if="type == 'contact-form'" class="modal-body text-center">
                                <p class="text-20 modal__text">Dziękujemy! Odezwiemy się tak szybko, jak to możliwe. </p>
                            </div>
                            <div v-if="type == 'server-error'" class="modal-body text-center">
                                <p>Nieoczekiwany błąd serwera. <br>
                                Skontaktuj się z organizatorem konkursu przez formularz kontaktowy.</p>
                            </div>
                            <div class="modal-footer d-flex justify-content-center">
                                <router-link :to="{ name: 'home' }" class="button__blue">Wróć do strony głównej</router-link>
                            </div>
                            <div v-if="type == 'contact-form'" class="modal-body text-center">
                                <button type="button" class="modal__extra-link text-16 form__title" data-bs-dismiss="modal"
                                    @click="$emit('closeModal')">Zostań na stronie formularza</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>


export default {
    emits: [],
    data() {
        return {
        }
    },
    props: ['type'],
    components: {
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
};
</script>