<template>
    <section-layout class="section-banner">
        <div class="section-banner__container">
            <div class="section-banner__banner">
                <img src="@/assets/header_mobile_xs.jpg" alt="" class="w-100 section-banner__img-mobile-xs" />
                <img src="@/assets/header_mobile.jpg" alt="" class="w-100 section-banner__img-mobile" />
                <div class="container">
                    <div class="section-banner__content d-flex align-items-center">
                        <div>
                            <img src="@/assets/logo.svg" alt="" class="section-banner__logo" />
                            <div v-if="stageStatus == 1">
                                <div class="section-banner__title heading-32">
                                    Pokaż swój talent i wygraj stypendium w Enei Akademii Talentów!
                                </div>
                                <p class="section-banner__text text-16">
                                    To już V edycja naszej wyjątkowej akcji, a w niej do wygrania stypendia aż po 5000 zł.
                                    Masz talent do nauki, sztuki, sportu, a może z pasją angażujesz się społecznie? Czekamy
                                    na Ciebie!
                                </p>
                            </div>
                            <div v-if="stageStatus == 2">
                                <div class="section-banner__title heading-32">
                                    Pokaż swój talent i wygraj stypendium w Enei Akademii Talentów!
                                </div>
                                <p class="section-banner__text text-16">
                                    To już V edycja naszej wyjątkowej akcji, a w niej do wygrania stypendia aż po 5000 zł.
                                    Masz talent do nauki, sztuki, sportu, a może z pasją angażujesz się społecznie? Czekamy
                                    na Ciebie!
                                </p>
                                <router-link :to="{ name: 'signUpStudent' }" class="button__red">Zgłoś swój udział<img
                                        src="@/assets/right-btn.svg" alt="" class="button__arrow" /></router-link>
                            </div>
                            <div v-if="stageStatus == 3">
                                <div class="section-banner__title heading-32">
                                    Zakończyliśmy I etap <br>Enei Akademii Talentów!
                                </div>
                                <p class="section-banner__text text-16">
                                    Dziękujemy za Wasze zgłoszenia! Już 10 listopada dowiemy się, kto przechodzi do II etapu
                                    i powalczy dalej o 5000 zł stypendium. Zostańcie z nami!
                                </p>
                            </div>
                            <div v-if="stageStatus == 4">
                                <div class="section-banner__title heading-32">
                                    Wiemy już, kto przechodzi do II etapu Enei Akademii Talentów!
                                </div>
                                <p class="section-banner__text text-16">
                                    Talenty, które zmierzą się w drugiej rundzie, już wybrane! Zakończyliśmy I etap
                                    konkursu, a jego finaliści otrzymali maile informujące, że przechodzą do II etapu. Śledź
                                    rywalizację dalej aż do wielkiego finału!
                                </p>
                            </div>
                            <div v-if="stageStatus == 5">
                                <div class="section-banner__title heading-32">
                                    Wiemy już, kto przechodzi do II etapu Enei Akademii Talentów!
                                </div>
                                <p class="section-banner__text text-16">
                                    Talenty, które zmierzą się w drugiej rundzie, już wybrane! Zakończyliśmy I etap
                                    konkursu, a jego finaliści otrzymali maile informujące, że przechodzą do II etapu. Śledź
                                    rywalizację dalej aż do wielkiego finału!
                                </p>
                            </div>
                            <div v-if="stageStatus == 6">
                                <div class="section-banner__title heading-32">
                                    Wiemy już, kto przechodzi do II etapu Enei Akademii Talentów!
                                </div>
                                <p class="section-banner__text text-16">
                                    Talenty, które zmierzą się w drugiej rundzie, już wybrane! Zakończyliśmy I etap
                                    konkursu, a jego finaliści otrzymali maile informujące, że przechodzą do II etapu. Śledź
                                    rywalizację dalej aż do wielkiego finału!
                                </p>
                            </div>
                            <div v-if="stageStatus == 7">
                                <div class="section-banner__title heading-32">
                                    Zobacz największe talenty <br>
                                    Enei Akademii Talentów i zagłosuj!
                                </div>
                                <p class="section-banner__text text-16">
                                    Zbliżamy się do wielkiego finału konkursu. <br>
                                    Już możesz zobaczyć najlepsze młode talenty <br>
                                    z całej Polski i zagłosować na swojego faworyta. <br>
                                    Kto wygra 5000 zł stypendium? Ty decydujesz!
                                </p>
                                <router-link :to="{ name: 'applications' }" class="button__red">Głosowanie<img
                                        src="@/assets/right-btn.svg" alt="" class="button__arrow" /></router-link>
                            </div>
                            <div v-if="stageStatus == 8">
                                <div class="section-banner__title heading-32">
                                    Głosowanie zakończone!
                                </div>
                                <p class="section-banner__text text-16">
                                    Zakończyliśmy głosowanie w konkursie<br>
                                    Enei Akademii Talentów. Dziękujemy za<br>
                                    Wasze głosy. Wyniki poznamy już 24 stycznia!<br>    
                                    Tego dnia widzimy się tutaj w wielkim finale!
                                </p>
                            </div>
                            <div v-if="stageStatus == 9">
                                <div class="section-banner__title heading-32">
                                    Wielki finał Enei<br>
                                    Akademii Talentów!<br>
                                    Poznaj zwycięzców
                                </div>
                                <p class="section-banner__text text-16">
                                    Dotarliśmy już do finału konkursu.<br>
                                    Kto wygrał i zdobył superstypendium<br>
                                    na rozwój swojej pasji? Zobacz 40 finalistów<br>
                                    tej edycji. Gratulujemy zwycięzcom!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container d-flex justify-content-center" v-if="stageStatus == 2">
                <div
                    class="col-24 col-md-20 col-lg-18 section-banner__counter color-blue d-flex flex-column flex-sm-row justify-content-between">
                    <div class="d-flex flex-column flex-sm-row align-items-center">
                        <img src="@/assets/watch.svg" alt="" class="section-banner__watch" />
                        <p class="section-banner__counter-text text-24 text-center"> Termin składania zgłoszeń upływa
                            za: </p>
                    </div>
                    <div class="section-banner__counter-time d-flex">
                        <div>
                            <p class="section-banner__counter-days heading-48"> {{ dataCount.d }} </p>
                            <p class="text-center"> dni </p>
                        </div>
                        <img src="@/assets/dot.svg" alt="" class="section-banner__counter-dot" />
                        <div>
                            <p class="section-banner__counter-hours heading-48"> {{ dataCount.h }} </p>
                            <p class="text-center"> godzin </p>
                        </div>
                        <p class="heading-48"> : </p>
                        <div>
                            <p class="section-banner__counter-minutes heading-48"> {{ dataCount.m }} </p>
                            <p class="text-center"> minut </p>
                        </div>
                        <p class="heading-48"> : </p>
                        <div>
                            <p class="section-banner__counter-seconds heading-48 blue"> {{ dataCount.s }} </p>
                            <p class="text-center"> sekund </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section-layout>
</template>

<script>
export default {
    data() {
        return {
            dataCount: {
                d: null,
                h: null,
                m: null,
                s: null,
            },
        }
    },
    methods: {
        showRemaining() {
            const end = new Date('10/20/2023 11:59 PM');

            const _second = 1000;
            const _minute = _second * 60;
            const _hour = _minute * 60;
            const _day = _hour * 24;

            const now = new Date();
            const distance = end - now;
            if (distance < 0) {

                this.dataCount.d = '00';
                this.dataCount.h = '00';
                this.dataCount.m = '00';
                this.dataCount.s = '00';

                return;
            }
            var days = Math.floor(distance / _day);


            var hours = Math.floor((distance % _day) / _hour);
            var minutes = Math.floor((distance % _hour) / _minute);
            var seconds = Math.floor((distance % _minute) / _second);



            this.dataCount.d = days < 10 ? `0${days}` : days;
            this.dataCount.h = hours < 10 ? `0${hours}` : hours;
            this.dataCount.m = minutes < 10 ? `0${minutes}` : minutes;
            this.dataCount.s = seconds < 10 ? `0${seconds}` : seconds;
        }
    },
    mounted() {
        setInterval(this.showRemaining, 1000);
    },
    computed: {
        applicationActive() {
            return this.$store.state.applicationActive
        },
        votesActive() {
            return this.$store.state.votesActive
        },
        finalistActive() {
            return this.$store.state.finalistActive
        },
        stageStatus() {
            return this.$store.state.stage
        }
    }
};
</script>
