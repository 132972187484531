<template>
    <nav class="menu js-menu-toggle" aria-label="primary-navigation">
        <div class="menu__item">
            <router-link :to="{ name: 'about' }" class="menu__link text-center"><p>O programie</p></router-link>
        </div>
        <div class="menu__item">
            <router-link :to="{ name: 'news' }" class="menu__link text-center"><p>Aktualności</p></router-link>
        </div>
        <div class="menu__item">
            <router-link :to="{ name: 'download' }" class="menu__link text-center"><p>Do pobrania</p></router-link>
        </div>
        <div class="menu__item">
            <router-link :to="{ name: 'contact' }" class="menu__link text-center"><p>Kontakt</p></router-link>
        </div>
        <div class="d-none d-sm-block menu__item menu__item--full" v-if="stageStatus == 2" >
            <router-link :to="{ name: 'signUpStudent' }" class="button__red menu__link menu__btn-full">Zgłoś się<img src="@/assets/right-btn.svg" alt="" class="button__arrow" /></router-link>
        </div>
        <div class="container d-sm-none menu__item menu__item--full" v-if="stageStatus == 2" >
            <router-link :to="{ name: 'signUpStudent' }" class="button__red menu__link menu__btn-full menu__btn">Zgłoś się<img src="@/assets/right-btn.svg" alt="" class="button__arrow d-none d-sm-block" /></router-link>
        </div>
        <div class="d-none d-sm-block menu__item menu__item--full" v-if="stageStatus == 6" >
            <router-link :to="{ name: 'applications' }" class="button__red menu__link menu__btn-full">Finaliści<img src="@/assets/right-btn.svg" alt="" class="button__arrow" /></router-link>
        </div>
        <div class="container d-sm-none menu__item menu__item--full" v-if="stageStatus == 6" >
            <router-link :to="{ name: 'applications' }" class="button__red menu__link menu__btn-full">Finaliści<img src="@/assets/right-btn.svg" alt="" class="button__arrow" /></router-link>
        </div>
        <div class="d-none d-sm-block menu__item menu__item--full" v-if="stageStatus == 8" >
            <router-link :to="{ name: 'applications' }" class="button__red menu__link menu__btn-full">Finaliści<img src="@/assets/right-btn.svg" alt="" class="button__arrow" /></router-link>
        </div>
        <div class="container d-sm-none menu__item menu__item--full" v-if="stageStatus == 8" >
            <router-link :to="{ name: 'applications' }" class="button__red menu__link menu__btn-full">Finaliści<img src="@/assets/right-btn.svg" alt="" class="button__arrow" /></router-link>
        </div>
        <div class="d-none d-sm-block menu__item menu__item--full" v-if="stageStatus == 7" >
            <router-link :to="{ name: 'applications' }" class="button__red menu__link menu__btn-full">Głosowanie<img src="@/assets/right-btn.svg" alt="" class="button__arrow" /></router-link>
        </div>
        <div class="container d-sm-none menu__item menu__item--full" v-if="stageStatus == 7" >
            <router-link :to="{ name: 'applications' }" class="button__red menu__link menu__btn-full">Głosowanie<img src="@/assets/right-btn.svg" alt="" class="button__arrow" /></router-link>
        </div>
        <div class="d-none d-sm-block menu__item menu__item--full" v-if="stageStatus == 9" >
            <router-link :to="{ name: 'winners' }" class="button__red menu__link menu__btn-full">Sprawdź Zwycięzców<img src="@/assets/right-btn.svg" alt="" class="button__arrow" /></router-link>
        </div>
        <div class="container d-sm-none menu__item menu__item--full" v-if="stageStatus == 9" >
            <router-link :to="{ name: 'winners' }" class="button__red menu__link menu__btn-full menu__btn">Sprawdź Zwycięzców<img src="@/assets/right-btn.svg" alt="" class="button__arrow d-none d-sm-block" /></router-link>
        </div>
    </nav>
</template>

<script>
export default {
    methods: {
        remove() {
            document.querySelector(".js-menu-toggle").classList.remove("menu--active");
            document.querySelector(".js-hamburger").classList.remove("hamburger--active");
        },
        handleTrackingFormButtonClickEvent() {
            this.$gtm.trackEvent({
                event: "formButtonClick",
            });
        },
    },
    computed: {
        applicationActive() {
            return this.$store.state.applicationActive
        },
        loteryActive() {
            return this.$store.state.loteryActive
        },
        votesActive() {
            return this.$store.state.votesActive
        },
        finalistActive() {
            return this.$store.state.finalistActive
        },
        showApplication() {
            return this.$store.state.showApplication
        },
        stageStatus() {
            return this.$store.state.stage
        }
    },
};
</script>
