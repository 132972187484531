<template>
    <div class="form-data">
        <div v-for="(item, index) in form" :key="'achievements_' + index">
            <div class="heading-24 form__title mb-4 text-center">
                <div v-if="index == 0">I aktywność</div>
                <div class="-pt-4" v-if="index == 1">II aktywność</div>
                <div class="-pt-4" v-if="index == 2">III aktywność</div>
            </div>
            <div class="row form__gap-24">
                <div class="col-md-12 text-18">
                    <p class="form__title text-18 form__pb-8">
                        Rodzaj aktywności społecznej
                        <PopperPopper :hover="true" :arrow="true" content="Działalność na rzecz społeczności lokalnych oraz środowiska szkolnego, np. warsztaty, spotkania, zajęcia dodatkowe, pomoc w pakowaniu zakupów itd.">
                            <button class="form__tooltip"></button>
                        </PopperPopper>
                        <span class="form__label form__label--red" v-if="index == 0">*</span>
                    </p>
                    <div :class="{ 'vue-select__error': typeError(index) }">
                        <vue-select :close-on-select="true" v-model="item.type" :options="inputs.types_social_activity"
                            placeholder="Rodzaj aktywności społecznej" label-by="name" value-by="value"
                            @selected="removeError(index, 'type')">
                            <template #label="{ selected }">
                                <template v-if="selected">
                                    <div>{{ selected.name }}</div>
                                </template>
                            </template>
                        </vue-select>
                    </div>
                    <div class="text-danger" v-if="typeError(index)">
                        {{ typeError(index) }}
                    </div>
                </div>
                <div class="col-md-12">
                    <p class="form__title text-18 form__pb-8">Rola w aktywności społecznej<span
                            class="form__label form__label--red" v-if="index == 0">*</span></p>
                    <div :class="{ 'vue-select__error': roleError(index) }">
                        <vue-select :close-on-select="true" v-model="item.role" :options="inputs.roles_social_activity"
                            placeholder="Określ rolę w aktywności" label-by="name" value-by="value"
                            @selected="removeError(index, 'role')">
                            <template #label="{ selected }">
                                <template v-if="selected">
                                    <div>{{ selected.name }}</div>
                                </template>
                            </template>
                        </vue-select>
                    </div>
                    <div class="text-danger" v-if="roleError(index)">
                        {{ roleError(index) }}
                    </div>
                </div>
                <div class="col-md-12 text-18">
                    <text-input v-model="item.title" :data="inputs.title" :index="index" />
                </div>
                <div class="col-md-12">
                    <p class="form__title text-18 form__pb-8">Zasięg aktywności społecznej<span
                            class="form__label form__label--red text-18" v-if="index == 0">*</span></p>
                    <div :class="{ 'vue-select__error': degreeError(index) }">
                        <vue-select :close-on-select="true" v-model="item.degree_social" :options="inputs.degree"
                            placeholder="Określ zasięg aktywności" label-by="name" value-by="name"
                            @selected="removeError(index, 'degree_social')">
                            <template #label="{ selected }">
                                <template v-if="selected">
                                    <div>{{ selected.name }}</div>
                                </template>
                            </template>
                        </vue-select>
                    </div>
                    <div class="text-danger" v-if="degreeError(index)">
                        {{ degreeError(index) }}
                    </div>
                </div>
                <div class="col-24">
                    <textarea-ianput :index="index" v-model="item.desc" class="form__title text-18"
                        :data="inputs.student_characterization" :errors="errors" />
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center -pt-5">
            <button class="button__red " @click="addAchievement" :disabled="form.length > 2 || buttonAchievments">
                <p v-if="form.length < 3">Dodaj kolejna aktywność</p>
                <p v-if="form.length === 3">Dodano maksymalną ilość aktywności</p>
                <img src="@/assets/add-btn.svg" alt="" class="button__arrow" />
            </button>
        </div>
    </div>
</template>

<script>
import VueNextSelect from 'vue-next-select'
import TextInput from "@/components/inputs/TextInput.vue";
import TextareaIanput from "@/components/inputs/TextareaIanput.vue";

export default {
    emits: ['update:modelValue', 'type'],
    props: ['errors', 'modelValue'],
    data() {
        return {
            inputs: {
                student_characterization: {
                    type: 'textarea',
                    title: 'Krótki opis aktywności społecznej',
                    placeholder: 'Krótki opis aktywności społecznej',
                    tooltip: 'Należy podać informacje opisujące charakter i zakres aktywności.',
                    subtitle: null,
                    mandatory: '*',
                    model: 'most_achievments_desc',
                    stageIndex: 'formStudent.student.most_achievements.stageIndex.desc'
                },
                title: {
                    type: 'text',
                    title: 'Tytuł aktywności społecznej',
                    placeholder: 'Wpisz tytuł aktywności',
                    subtitle: null,
                    mandatoryAchievements: '*',
                    model: 'most_achievments_title',
                    stageIndex: 'formStudent.student.most_achievements.stageIndex.title'
                },
                types_social_activity: [
                    {
                        name: 'Wydarzenie',
                        value: 'event'
                    },
                    {
                        name: 'Kampania internetowa',
                        value: 'onlinecampaign'
                    },
                ],
                roles_social_activity: [
                    {
                        name: 'Organizator',
                        value: 'organizer'
                    },
                    {
                        name: 'Działania wspierające',
                        value: 'helper'
                    },
                    {
                        name: 'Uczestnik',
                        value: 'user'
                    },
                ],
                degree: [
                    {
                        name: 'międzynarodowy',
                        value: 1
                    },
                    {
                        name: 'ogólnopolski',
                        value: 1
                    },
                    {
                        name: 'wojewódzki',
                        value: 1
                    },
                    {
                        name: 'regionalny (miejski)',
                        value: 1
                    },
                    {
                        name: 'szkolny',
                        value: 1
                    },
                    {
                        name: 'inny',
                        value: 1
                    },
                ],
            },
            form: [
                {
                    type: null,
                    role: null,
                    title: null,
                    degree_social: null,
                    desc: ''
                }
            ]
        }
    },
    components: {
        'vue-select': VueNextSelect,
        TextInput,
        TextareaIanput
    },
    computed: {
        buttonAchievments() {
            return this.form.filter((n) => !n.type || !n.role || !n.title || n.name == '' || !n.degree_social || !n.desc || n.desc == '').length;
        }
    },
    watch: {
        form: {
            handler(newVal) {
                this.$emit('update:modelValue', newVal)
                this.updateFormValue('formStudent.student.most_achievements', newVal);
            },
            deep: true
        }
    },
    methods: {
        removeError(index, value) {
            this.$store.commit('removeError', 'student.most_achievements.' + index + '.' + value)
        },
        typeError(index) {
            const error = this.$store.getters.getError('student.most_achievements.' + index + '.type');
            if (error && error.length)
                return error[0]

            return null
        },
        roleError(index) {
            const error = this.$store.getters.getError('student.most_achievements.' + index + '.role');
            if (error && error.length)
                return error[0]

            return null
        },
        degreeError(index) {
            const error = this.$store.getters.getError('student.most_achievements.' + index + '.degree_social');
            if (error && error.length)
                return error[0]

            return null
        },
        scoreError(index) {
            const error = this.$store.getters.getError('student.most_achievements.' + index + '.score');
            if (error && error.length)
                return error[0]

            return null
        },
        addAchievement() {
            this.form.push(
                {
                    type: null,
                    role: null,
                    title: null,
                    degree_social: null,
                    desc: ''
                }
            );
        }
    },
};
</script>
