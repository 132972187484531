<template>
    <section-layout class="section-about">
        <div class="section-about__content">
            <div class="d-flex flex-column flex-sm-row">
                <img src="@/assets/about-what.jpg" alt="" class="section-about__image col-sm-12" />
                <div class="col-sm-12 d-flex flex-column justify-content-center -pl-sm-5">
                    <div class="col-lg-19">
                        <p class="section-about__title heading-40 color-blue">Poznaj Eneę Akademię Talentów</p>
                        <p class="text-16">
                            Nasza Akademia to wspólna akcja Enei i Fundacji Enea, w której wspieramy młode talenty w rozwijaniu ich największych pasji poprzez atrakcyjne stypendia. <br> To już V edycja, a w niej do wygrania łącznie <span class="heading-16 color-blue"> aż 40 stypendiów po 5000 zł</span> w czterech kategoriach: <span class="heading-16 color-blue">nauka, sztuka, sport</span> oraz <span class="heading-16 color-blue">zaangażowanie społeczne.</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="row -pt-4 -pt-sm-12 d-flex align-items-center">
                <img src="@/assets/about-who.jpg" alt="" class="section-about__image col-sm-12 order-sm-2" />
                <div class="col-sm-12 text-16 -pr-sm-5 order-sm-1">
                    <div class="col-lg-19">
                        <p class="section-about__title heading-40 color-blue">Kto może się zgłosić do konkursu?</p>
                        <p class="">Do naszej akcji zapraszamy wszystkich:  </p>
                        <ul class="d-flex flex-column list">
                            <li>uczniów <span class="heading-16 color-blue">szkół podstawowych (klasy 4-8),   </span></li>
                            <li>uczniów <span class="heading-16 color-blue">szkół ponadpodstawowych z całej Polski! </span></li>
                        </ul>
                        <p>Zgłoś się w kategorii, która odpowiada Twojemu talentowi, i graj o <span class="heading-16 color-blue">5000 zł, które możesz przeznaczyć na rozwój pasji!</span></p>
                    </div>
                </div>
            </div>
        </div>

    </section-layout>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
    },
    components: {
    },
    computed: {
    },
};
</script>
